import { BackgroundImage, FlowWrapper } from '@arcadehq/shared/types'

import { AccountMixin } from '../AccountCore'
import { getSubfield, setOrDeleteSubfield } from '../helpers'

export interface FlowDefaults {
  font: string | undefined
  backgroundImage: BackgroundImage | undefined
  wrapper: FlowWrapper | undefined // TODO correct type
  navBarEnabled: boolean
  startOverlayEnabled: boolean
  startOverlayText: string | undefined

  setFont: (font?: string) => Promise<boolean>
  setBackgroundImage: (backgroundImage?: BackgroundImage) => Promise<boolean>
  setWrapper: (wrapper?: FlowWrapper) => Promise<boolean>
  setNavBarEnabled: (navBarEnabled: boolean) => Promise<boolean>
  setStartOverlayEnabled: (startOverlayEnabled: boolean) => Promise<boolean>
  setStartOverlayText: (startOverlayText?: string) => Promise<boolean>
}

export const flowDefaults: AccountMixin<FlowDefaults> = (core, { team }) => ({
  get font() {
    if (team) {
      return getSubfield(team, 'prefs', 'fontFamily')
    }
    // TODO check user profile
  },

  get backgroundImage() {
    if (team) {
      return getSubfield(team, 'prefs', 'bgImage')
    }
  },

  get wrapper() {
    if (team) {
      return getSubfield(team, 'prefs', 'wrapper')
    }
  },

  get navBarEnabled() {
    if (team) {
      return !!getSubfield(team, 'prefs', 'showFlowNavIndicator')
    }
    return false
  },

  get startOverlayEnabled() {
    if (team) {
      return !!getSubfield(team, 'prefs', 'showStartOverlay')
    }
    return false
  },

  get startOverlayText() {
    if (team) {
      return getSubfield(team, 'prefs', 'startOverlayButtonText')
    }
  },

  setFont(font: string | undefined) {
    if (team) {
      return setOrDeleteSubfield(team, 'prefs', 'fontFamily', font, core.userId)
    }
    // TODO set user profile
    return Promise.resolve(false)
  },

  setBackgroundImage(backgroundImage: BackgroundImage | undefined) {
    if (team) {
      return setOrDeleteSubfield(
        team,
        'prefs',
        'bgImage',
        backgroundImage,
        core.userId
      )
    }
    return Promise.resolve(false)
  },

  setWrapper(wrapper: FlowWrapper | undefined) {
    if (team) {
      return setOrDeleteSubfield(team, 'prefs', 'wrapper', wrapper, core.userId)
    }
    return Promise.resolve(false)
  },

  setNavBarEnabled(navBarEnabled: boolean) {
    if (team) {
      return setOrDeleteSubfield(
        team,
        'prefs',
        'showFlowNavIndicator',
        navBarEnabled,
        core.userId
      )
    }
    return Promise.resolve(false)
  },

  setStartOverlayEnabled(startOverlayEnabled: boolean) {
    if (team) {
      return setOrDeleteSubfield(
        team,
        'prefs',
        'showStartOverlay',
        startOverlayEnabled,
        core.userId
      )
    }
    return Promise.resolve(false)
  },

  setStartOverlayText(startOverlayText?: string) {
    if (team) {
      return setOrDeleteSubfield(
        team,
        'prefs',
        'startOverlayButtonText',
        startOverlayText,
        core.userId
      )
    }
    return Promise.resolve(false)
  },
})
