import {
  FlowDataDoc,
  FlowWrapper,
  PublishStatus,
  Step,
  StepType,
} from '@arcadehq/shared/types'
import omit from 'lodash/omit'
import pick from 'lodash/pick'
import { Flow, FlowData, FlowPublicData } from 'src/types'

import {
  getFromSerialized,
  getSerializable,
  SerializableData,
} from './serializable'
import { isStepWithSize } from './steps'

export function getSerializablePublicFlow(
  flow: Flow
): SerializableData<FlowPublicData> {
  return getSerializable({
    ...pick(flow, [
      'id',
      'created',
      'modified',
      'name',
      'description',
      'aspectRatio',
      'cta',
      'steps',
      'createdBy',
      'editors',
      'status',
      'submittedForSpotlight',
      'flowWrapper',
      'font',
      'bgImage',
      'group',
      'showArcadeButton',
      'openingAnimation',
      'belongsToTeam',
      'showFlowNavIndicator',
      'optimizeFlowForMobileView',
      'showStartOverlay',
      'startOverlayButtonText',
      'preventIndexing',
      'structureHash',
      'autoplay',
      'backgroundMusicUrl',
    ]),
    // Don't leak the actual timestamp but "anonymize" it to 1970-01-01
    firstViewed: flow.firstViewed ? new Date(0) : null,
    steps: flow.steps.map(step =>
      pick(step, [
        'id',
        'type',
        'name',
        'targetId',
        'hotspots',
        'size',
        // Image
        'url',
        'blurhash',
        'panAndZoom',
        'syntheticVoice',
        // Video
        'streamUrl',
        'videoThumbnailUrl',
        'playbackRate',
        'assetId',
        'startTimeFrac',
        'endTimeFrac',
        'duration',
        'muted',
        'videoProcessing',
        // Overlay
        'title',
        'subtitle',
        'actionUrl',
        'buttonText',
        'buttonColor',
        'buttonTextColor',
        'buttonType',
        'theme',
        'blur',
        'paths',
        // Legacy
        'cta',
      ])
    ),
  })
}

export function getFlowFromSerializablePublicFlow(
  publicFlow: SerializableData<FlowPublicData>
): Flow {
  const deserializedFlow = getFromSerialized<FlowPublicData>(publicFlow)
  return {
    ...deserializedFlow,
    schemaVersion: '',
    experiments: [],
    experimentsConfig: {},
    gifUrl: '',
    videoUrl: '',
    checksum: '',
    tagIds: [],
    publishedDate: new Date(),
    lastModifiedBy: '',
    uploadId: '',
    exportProgress: null,
    lastExportFailedAt: null,
    // Don't leak the actual timestamp but "anonymize" it to 1970-01-01
    firstViewed: deserializedFlow.firstViewed ? new Date(0) : null,
    update: async (entity: Partial<FlowData>, userId: string | null) => {
      if (!userId) return false
      // ensure firebase is not bundled in the viewer
      const { updateFlow } = await import('src/store/flows')
      return updateFlow(deserializedFlow.id, entity, userId)
    },
  }
}

// TODO: Ideally we should share the `Flow` type between apps
// and not have to do this.
export function getFlowDataDocFromFlow(flow: Flow): FlowDataDoc {
  return {
    ...omit(flow, ['id', 'created', 'modified', 'createdBy', 'lastModifiedBy']),
  }
}

export const getFirstStepWithSize = (steps: Step[]) => {
  return steps.find(isStepWithSize)
}

export function getEmptyFlow(data: Partial<Flow> = {}): Flow {
  return {
    id: '',
    name: '',
    description: '',
    schemaVersion: '',
    uploadId: '',
    aspectRatio: 1,
    steps: [],
    cta: {},
    editors: [],
    status: PublishStatus.draft,
    submittedForSpotlight: false,
    flowWrapper: FlowWrapper.browserLight,
    font: '',
    bgImage: null,
    group: '',
    experiments: [],
    experimentsConfig: {},
    showArcadeButton: false,
    openingAnimation: null,
    belongsToTeam: false,
    showFlowNavIndicator: false,
    optimizeFlowForMobileView: false,
    showStartOverlay: false,
    startOverlayButtonText: '',
    preventIndexing: false,
    structureHash: '',
    autoplay: false,
    publishedDate: undefined,
    createdBy: '',
    lastModifiedBy: '',
    created: new Date(),
    modified: new Date(),
    gifUrl: '',
    videoUrl: '',
    checksum: '',
    tagIds: [],
    backgroundMusicUrl: '',
    exportProgress: null,
    lastExportFailedAt: null,
    firstViewed: null,
    update: async () => false,
    delete: async () => {},
    ...data,
  }
}

export function flowHasAudio(flow: Flow) {
  return (
    flow.backgroundMusicUrl !== '' ||
    flow.steps.some(step => step.type === StepType.Video && !step.muted) ||
    flow.steps.some(step => step.syntheticVoice?.url)
  )
}

export function flowHasVoiceover(flow: Flow) {
  return flow.steps.some(step => step.syntheticVoice?.url)
}

export function isOnLastStep(flow: Flow, currentStepId: string) {
  return flow.steps[flow.steps.length - 1].id === currentStepId
}
