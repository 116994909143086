import { Step, StepType } from '@arcadehq/shared/types'
import { v4 as uuid } from 'uuid'

export function getNewStepFromPartial(
  step: Partial<Step> & { type: StepType }
): Step {
  return {
    ...step,
    id: step.id ?? uuid(),
    name: '',
    url: step.url ?? '',
    ...(step.type === StepType.Video
      ? {
          playbackRate: step.playbackRate || 1,
          streamUrl: step.streamUrl,
          videoThumbnailUrl:
            step.videoThumbnailUrl ||
            getThumbnailUrlFromVideoStreamUrl(step.url),
          startTimeFrac: step.startTimeFrac || 0,
          endTimeFrac: step.endTimeFrac || 1,
          duration: step.duration || 0,
        }
      : {}),
    hotspots: step.hotspots ?? [],
    cta: {},
  }
}

export function getStepPreviewUrl(step: Step | undefined): string {
  if (!step) {
    return '/images/empty-flow.svg'
  }
  if (step.type === StepType.Image) {
    return step.url || step.imageUrl || '/images/empty-flow.svg'
  }

  if (step.type === StepType.Video) {
    return (
      step.videoThumbnailUrl ||
      getThumbnailUrlFromVideoStreamUrl(step.streamUrl || step.url)
    )
  }

  return '/images/empty-flow.svg'
}

export function getThumbnailUrlFromVideoStreamUrl(
  streamUrl?: string,
  time: number = 0
): string {
  return (
    streamUrl
      ?.replace('stream', 'image')
      .replace('.m3u8', `/thumbnail.png?time=${time}`) ?? ''
  )
}

export function getStepName(
  step: Step | undefined = undefined,
  index: number = 0
) {
  if (!step) {
    return ''
  }
  let title = `Step ${index + 1}`
  if (step.title) {
    title = `${step.title}`
  } else if (step.hotspots.length > 0 && step.hotspots[0].label) {
    title = `${step.hotspots[0].label}`
  }
  return title
}

export function isStepWithSize(step: Step | undefined) {
  return step?.type === StepType.Image || step?.type === StepType.Video
}
